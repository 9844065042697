import React from 'react'
import switchBreak from '../styles/switchBreak'

export default function Inquiry() {
  return (
    <section id="inquiry" className="container">
      <h1>Inquiry</h1>
      <p>
        For any questions concerning our services,<br css={switchBreak('pc')} />
        please inquire through the following contacts.<br />
        <a href="mailto:info@paddling.co.jp">info@paddling.co.jp</a>
      </p>
      <p>
        For any questions concerning our sales services,<br css={switchBreak('pc')} />
        please inquire through the following contacts.<br />
        <a href="mailto:sales@paddling.co.jp">sales@paddling.co.jp</a>
      </p>
    </section>
  )
}