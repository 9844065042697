import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'gatsby'

import * as localStyles from './header.module.scss'

import switchBreak from '../styles/switchBreak'

export default function Header() {
  function toggleNav(e) {
    // e.preventDefault();
    const nav = document.querySelector('.drawer-nav')
    const menu = document.querySelector('.menu')
    nav.classList.toggle('drawer-nav--enable')
    menu.classList.toggle('menu--enable')
  }

  return (
    <header
      className="flex items-center justify-between h-[100px] fixed top-0 left-0 w-full bg-white pt-0 px-[5%] z-10">
      <Link to="/" className="block w-48">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
             y="0px" width="484.9px" height="51.58px" viewBox="0 0 484.9 51.58"
             className="w-full h-auto"
             style={{ overflow: 'visible', enableBackground: 'new 0 0 484.9 51.58' }} xmlSpace="preserve"
        >
          <defs>
          </defs>
          <desc>Paddle inc.</desc>
          <g>
            <path d="M0,2.09c0-0.72,0.57-1.36,1.37-1.36H17.7c8.8,0,16.04,7.16,16.04,15.83c0,8.88-7.24,16.11-15.98,16.11H7.37v16.85
		c0,0.71-0.63,1.35-1.35,1.35H1.37C0.57,50.85,0,50.21,0,49.51V2.09z M17.26,25.66c4.95,0,9.1-4.03,9.1-9.17
		c0-4.88-4.15-8.61-9.1-8.61H7.37v17.78H17.26z" />
            <path d="M45.25,49.01l22-48.22C67.45,0.35,68.1,0,68.46,0h0.72c0.35,0,0.98,0.35,1.2,0.79l21.85,48.22
		c0.42,0.91-0.14,1.85-1.22,1.85H86.5c-0.87,0-1.36-0.42-1.65-1.08L77.46,33.8l-20.43,6.57c-1.43,3.29-2.96,6.13-4.4,9.41
		c-0.23,0.52-0.79,1.08-1.64,1.08h-4.52C45.39,50.85,44.82,49.92,45.25,49.01 M74.41,27.1l-5.52-12.91h-0.36l-7.68,17.17L74.41,27.1
		z" />
            <path d="M111.27,2.09c0-0.72,0.57-1.36,1.29-1.36h16.48c13.82,0,25.14,11.24,25.14,24.99c0,13.9-11.32,25.15-25.14,25.15h-16.48
		c-0.72,0-1.29-0.64-1.29-1.35V2.09z M128.03,44.06c10.53,0,18.18-7.76,18.18-18.35c0-10.52-7.65-18.19-18.18-18.19h-9.45v36.55
		H128.03z" />
            <path d="M176.36,2.09c0-0.72,0.57-1.36,1.29-1.36h16.48c13.82,0,25.14,11.24,25.14,24.99c0,13.9-11.32,25.15-25.14,25.15h-16.48
		c-0.72,0-1.29-0.64-1.29-1.35V2.09z M193.12,44.06c10.53,0,18.19-7.76,18.19-18.35c0-10.52-7.65-18.19-18.19-18.19h-9.46v36.55
		H193.12z" />
            <path d="M242.06,2.09c0-0.72,0.59-1.36,1.37-1.36h4.72c0.72,0,1.38,0.64,1.38,1.36v42.12h19.39c0.79,0,1.37,0.64,1.37,1.35v3.95
		c0,0.71-0.57,1.35-1.37,1.35h-25.49c-0.78,0-1.37-0.64-1.37-1.35V2.09z" />
            <path d="M289.68,2.09c0-0.72,0.58-1.36,1.38-1.36h28.64c0.78,0,1.35,0.64,1.35,1.36v4c0,0.72-0.57,1.38-1.35,1.38h-22.65v14.6
		h19.14c0.71,0,1.34,0.64,1.34,1.38v4c0,0.79-0.63,1.35-1.34,1.35h-19.14v15.42h22.65c0.78,0,1.35,0.64,1.35,1.35v3.95
		c0,0.71-0.57,1.35-1.35,1.35h-28.64c-0.8,0-1.38-0.64-1.38-1.35V2.09z" />
            <path d="M361.61,14.76c0-0.53,0.49-1,1.02-1h3.52c0.51,0,1,0.47,1,1v35.28c0,0.53-0.48,1.01-1,1.01h-3.52
		c-0.53,0-1.02-0.48-1.02-1.01V14.76z" />
            <path d="M385.69,14.18c0-0.53,0.48-0.95,1.01-0.95h1.33l23.12,26.74h0.06V14.76c0-0.53,0.43-1,1.02-1h3.4c0.54,0,1.02,0.47,1.02,1
		v35.86c0,0.55-0.48,0.96-1.02,0.96h-1.38l-23.11-27.49h-0.06v25.95c0,0.53-0.42,1.01-1.02,1.01h-3.36c-0.53,0-1.01-0.48-1.01-1.01
		V14.18z" />
            <path d="M451.09,13.23c5.39,0,9.27,1.8,12.89,4.95c0.49,0.42,0.49,1.06,0.07,1.49l-2.35,2.38c-0.38,0.5-0.85,0.5-1.33,0
		c-2.5-2.17-5.89-3.55-9.22-3.55c-7.61,0-13.36,6.38-13.36,13.83c0,7.47,5.8,13.82,13.42,13.82c3.89,0,6.6-1.56,9.16-3.53
		c0.48-0.35,0.96-0.32,1.28-0.05l2.46,2.39c0.43,0.38,0.32,1.06-0.05,1.45c-3.62,3.52-8.23,5.17-12.96,5.17
		c-10.65,0-19.25-8.46-19.25-19.12C431.83,21.79,440.43,13.23,451.09,13.23" />
            <path d="M481.18,44.16c2.08,0,3.73,1.65,3.73,3.74c0,2.02-1.65,3.68-3.73,3.68c-2.03,0-3.68-1.65-3.68-3.68
		C477.49,45.81,479.15,44.16,481.18,44.16" />
          </g>
        </svg>
      </Link>

      <ul className={`${localStyles.nav} nav`} role="navigation">
        <li>
          <div onClick={() => scrollTo('#activities')} onKeyDown={() => scrollTo('#activities')}>Activities</div>
        </li>
        <li>
          <div onClick={() => scrollTo('#message')} onKeyDown={() => scrollTo('#activities')}>Message</div>
        </li>
        <li>
          <div onClick={() => scrollTo('#corporate')} onKeyDown={() => scrollTo('#activities')}>Corporate</div>
        </li>
        <li>
          <div onClick={() => scrollTo('#inquiry')} onKeyDown={() => scrollTo('#activities')}>Inquiry</div>
        </li>
        <li>
          <a href="https://www.instagram.com/keeponpaddling/" target="_blank" className={localStyles.instagram}
             rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
      </ul>
      <div className="menu" onClick={toggleNav} css={switchBreak('sp')}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="drawer-nav" css={switchBreak('sp')} role="navigation">
        <ul>
          <li>
            <a onClick={() => {
              scrollTo('#activities')
              toggleNav()
            }}>Activities</a>
          </li>
          <li>
            <a onClick={() => {
              scrollTo('#message')
              toggleNav()
            }}>Message</a>
          </li>
          <li>
            <a onClick={() => {
              scrollTo('#corporate')
              toggleNav()
            }}>Corporate</a>
          </li>
          <li>
            <a onClick={() => {
              scrollTo('#inquiry')
              toggleNav()
            }}>Inquiry</a>
          </li>
          <li>
            <a onClick={toggleNav} href="https://www.instagram.com/keeponpaddling/" target="_blank"
               rel="noreferrer">Instagram</a>
          </li>
        </ul>
      </div>
    </header>
  )
}
