import React from "react";
import {Helmet} from "react-helmet";
import {useStaticQuery, graphql} from "gatsby";

export default function SEO(props) {
    const data = useStaticQuery(graphql`
        query{
            site {
                siteMetadata {
                    description
                    lang
                    title
                    siteUrl
                }
            }
        }
    `)

    const title = props.pagetitle
        ? `${props.pagetitle} | ${data.site.siteMetadata.title}`
        : data.site.siteMetadata.title

    const description = props.pagedesc || data.site.siteMetadata.description

    const url = props.pagepath
        ? `${data.site.siteMetadata.siteUrl}${props.pagepath}`
        : data.site.siteMetadata.siteUrl

    const imgurl = `${data.site.siteMetadata.siteUrl}/thumb.jpg`

    return (
        <Helmet>
            <html lang={data.site.siteMetadata.lang}/>
            <title>{title}</title>
            <meta name="description" content={data.site.siteMetadata.description}/>
            <link rel="canonical" href={url}/>

            <meta property="og:site_name" content={data.site.siteMetadata.title} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={imgurl} />
            <meta property="og:image:width" content="1280" />
            <meta property="og:image:height" content="640" />
            <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}