import React from "react";

import * as localStyle from "./activities.module.scss"

export default function Activities() {
  return (
    <section id="activities" className="min-h-screen">
      <h1>Activities</h1>
      <h2>Sales Division</h2>
      <div className="row">
        <div className="col">
          <p>
            Sales consulting
          </p>
          <p>
            Sales planning – cultivation of new markets and<br/>
            market control through a network of over 400<br/>
            distribution venues.
          </p>
          <p>
            Show planning – planning and coordination of<br/>
            season shows utilizing both our showroom and<br/>
            other locations.
          </p>
          <p>
            distributor negotiations – order compilation,<br/>
            product distribution coordination, sales terms<br/>
            negotiations and fund collections.
          </p>
          <p>
            and more.
          </p>
        </div>
        <div className="col">
          <h3 className="mt-10 lg:mt-0">Designers &lt;Distributor&gt;</h3>
          <div className="row">
            <ul className={`${localStyle.list} col`}>
              <li>
                GRIFFIN
              </li>
            </ul>
            <ul className={`${localStyle.list} col`}>
              <li>MIKIA</li>
              <li>
                REIGNING CHAMP
              </li>
            </ul>
          </div>
          <h3 className="mt-8">Previous Clients</h3>
          <ul className={localStyle.list}>
            <li>Abu Garcia</li>
            <li>CONVERSE TOKYO</li>
            <li>CULTURE&amp;COMMERCE / FRED SEGAL</li>
            <li>HOMECORE</li>
            <li>KICKS/HI</li>
            <li>MIZUNO</li>
            <li>NANAMICA</li>
            <li>SATURDAYS SURF NYC</li>
            <li>SCYE</li>
            <li>SHWOOD</li>
            <li>SIMON MILLER</li>
            <li>THOM BROWNE.NEW YORK</li>
            <li>WINGS AND HORNS</li>
          </ul>
        </div>
      </div>
      <h2 className={localStyle.publicRelationsDivision}>Public Relations Division</h2>
      <div className="row">
        <div className="col">
          <p>
            lease and supervision services for product samples.<br/>
            networking services with editors and stylists.<br/>
            reporting, filing, book making services of published<br/>
            articles.
          </p>
        </div>
        <div className="col">
          <p>
            item inquiries correspondence services.<br/>
            press kit compilation and supervision services.<br/>
            press coordination services for shows and events.<br/>
            communications and support services for promotions.
          </p>
        </div>
      </div>
    </section>
  )
}
