import React from 'react'
import switchBreak from '../styles/switchBreak'

import * as localStyle from './corporate.module.scss'

export default function Corporate() {
  return (
    <section id="corporate" className="container max-w-screen-lg">
      <h1>Corporate</h1>
      <div className="row">
        <div className="col">
          <h2 className={localStyle.subtitle}>Corporate Specifics</h2>
          <table className={localStyle.table}>
            <tbody>
            <tr>
              <th>Name</th>
              <td>paddle inc.</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>2-21-14-B1F Jingumae Shibuya-ku<br />Tokyo 150-0001 JAPAN</td>
            </tr>
            <tr>
              <th>Tel</th>
              <td><a href="tel:81364554411">+81-3-6455-4411</a></td>
            </tr>
            <tr>
              <th>Fax</th>
              <td>+81-3-6455-4422</td>
            </tr>
            <tr>
              <th>Founded</th>
              <td>25/Oct/2002</td>
            </tr>
            <tr>
              <th>President</th>
              <td>Fumio Osone</td>
            </tr>
            <tr>
              <th>Capital</th>
              <td>¥10,000,000</td>
            </tr>
            <tr>
              <th className="cell-services">Corporate<br />services</th>
              <td>
                Sales Representation <span css={switchBreak('pc_inline')}>/ </span><br css={switchBreak('sp')} />
                PR <br css={switchBreak('pc')} />
                Strategy <br css={switchBreak('sp')} />Planning and <br css={switchBreak('pc')} />
                Implementation <br css={switchBreak('sp')} />Contract Brand <br css={switchBreak('pc')} />
                Strategy<br />
                Planning and <br css={switchBreak('sp')} />Implementation <br css={switchBreak('pc')} />
                Contract Artist<br />
                and Designer Management<br />
                CI Planning
              </td>
            </tr>
            <tr>
              <th className="cell-services">Team paddle</th>
              <td>
                okay poke
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="col">
          <h2 className={`${localStyle.subtitle} mt-12 lg:mt-0`}>会社概要</h2>
          <table className={localStyle.table}>
            <tbody>
            <tr className={localStyle.fontJa}>
              <th>社名</th>
              <td>株式会社パドル</td>
            </tr>
            <tr className={localStyle.fontJa}>
              <th>所在地</th>
              <td>〒150-0001<br />東京都渋谷区神宮前 2-21-14<br />PLAZA F3 ビル B1</td>
            </tr>
            <tr>
              <th>Tel</th>
              <td><a href="tel:0364554411">03-6455-4411</a>
              </td>
            </tr>
            <tr>
              <th>Fax</th>
              <td>03-6455-4422</td>
            </tr>
            <tr className={localStyle.fontJa}>
              <th>設立</th>
              <td>2002年10月25日</td>
            </tr>
            <tr className={localStyle.fontJa}>
              <th>資本金</th>
              <td>1,000万円</td>
            </tr>
            <tr className={localStyle.fontJa}>
              <th>代表取締役</th>
              <td>大曽根 史夫</td>
            </tr>
            <tr className={localStyle.fontJa}>
              <th>事業内容</th>
              <td>
                セールスレップ／PR戦略<br />ブランド戦略の企画立案・実施運営<br />海外服飾雑貨輸入・卸売
              </td>
            </tr>
            <tr>
              <th>Team paddle</th>
              <td>
                okay poke
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}
