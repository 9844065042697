import React from 'react'

export default function Message() {
  return (
    <section id="message" className="container">
      <h1>Message</h1>
      <p>
        A long time has passed since we started our business in 2002.<br />
        When we negate and affirm the past, present and future simultaneously,<br />
        we’ve been able to take action and make the great things happen.
      </p>
      <p>
        We have been continuously producing quality fashion, stores/buildings, people and food.<br />
        Regardless of anything else, we are always eager to create more exciting moments that we think are
        interesting,<br />
        unique and innovative, and we would like to keep introducing them to the world out there.<br />
        This is our new company theme/motto.
      </p>
      <p>
        We have the most amazing team working on our projects.<br />
        And we are a group of people that support, encourage, and inspire one another to bring us reach our goals.<br />
        We are and will always be the company that seek the best results with our clients and partners.
      </p>
      <p>
        KEEP ON PADDLING<br />
        That is our mission.
      </p>
    </section>
  )
}