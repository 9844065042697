import {css} from "@emotion/react";
import {breakPoint} from "../constants/constants";

const switchBreak = mode => {
    switch (mode) {
        case "sp":
            return css`
        @media (min-width: ${breakPoint}) {
          display: none;
        }
      `;
        case "pc":
            return css`
        display: none;
        @media (min-width: ${breakPoint}) {
          display: block;
        }
      `;
        case "both":
            return css`
        display: block;
      `;
        case "sp_inline":
            return css`
                display: inline-block;
                @media (min-width: ${breakPoint}) {
                    display: none;
                }
            `;
        case "pc_inline":
            return css`
                display: none;
                @media (min-width: ${breakPoint}) {
                    display: inline-block;
                }
            `;
        default:
            return css`
        display: block;
      `;
    }
};

export default switchBreak