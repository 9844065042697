import React from 'react'

import 'modern-css-reset'

import Header from '../components/header'
import Footer from '../components/footer'

import '@fontsource/lato/300.css'
import '@fontsource/lato/400.css'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

config.autoAddCss = false

export default function Layout({ children }) {
  return (
    <div className="wrapper">
      <Header />
      {children}
      <Footer />
    </div>
  )
}