import React from "react"
import Layout from "../components/layout"

import Activities from "../components/activities";
import Message from "../components/message"
import Corporate from "../components/corporate"
import Inquiry from "../components/inquiry"
import SEO from "../components/seo"
import InstagramImages from '../components/instagram-images';

export default function Home() {
  return (
    <Layout>
      <SEO />
      <InstagramImages />
      <Activities/>
      <Message/>
      <Corporate/>
      <Inquiry/>
    </Layout>
  )
}
