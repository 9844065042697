import React from 'react'

const urls = [
  '/images/instagram-image-06.webp',
  '/images/instagram-image-05.webp',
  '/images/instagram-image-04.webp',
]
export default function InstagramImages() {
  return (
    <div className="flex feed h-screen items-center justify-center relative">
      {urls.map((url, i) => (
        <div
          key={`instagram-feed-${i}`}
          className="opacity-0 absolute lg:relative min-w-full lg:min-w-[33.3333%] lg:mx-[10px] aspect-square bg-cover bg-center"
          style={{backgroundImage: `url(${url})`}}
        >
        </div>
      ))}
    </div>
  )
}